import SignInForm from '../../../components/layoutComponents/signIn/SignIn'
import DashboardWrapper from '../../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapper'
import * as DashboardStyles from './Dashboard.module.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { LoginContext } from '../../../lib/utils'
import { navigate } from 'gatsby'
import SignUpForm from '../../../components/layoutComponents/signUp/SignUp'

const BSALogin = () => {
  const { usr } = useContext(LoginContext)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [toggleForm, setToggleForm] = useState<'signIn' | 'signUp'>('signIn')
  const [redirect, setRedirect] = useState<string>(
    '/best-schools-awards/dashboard/'
  )

  useEffect(() => {
    if (typeof window !== undefined) {
      // eslint-disable-next-line node/no-unsupported-features/node-builtins
      const params = new URLSearchParams(window.location?.search)
      const redirectParam = params.get('redirect')
      if (redirectParam) {
        setRedirect(redirectParam)
      }
    }
  }, [])

  useEffect(() => {
    if (usr?.viewer?.email) {
      setLoggedIn(true)
    }
  }, [usr])

  useEffect(() => {
    if (loggedIn) {
      navigate(redirect)
    }
  }, [loggedIn])

  return (
    <>
      <Helmet>
        <title>
          Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          <h1>Login</h1>
          <p>You must log in with your free Muddy account to enter.</p>
          <p>
            If you don&apos;t yet have one, head to the &quot;Sign Up&quot; tab
            and get one!
          </p>
          <div className={DashboardStyles.LoginToggles}>
            <div className={DashboardStyles.LoginToggleBoxes}>
              <div
                onClick={() => setToggleForm('signIn')}
                className={
                  toggleForm === 'signIn' ? DashboardStyles.ToggleBoxActive : ''
                }
              >
                Log in
              </div>
              <div
                onClick={() => setToggleForm('signUp')}
                className={
                  toggleForm === 'signUp' ? DashboardStyles.ToggleBoxActive : ''
                }
              >
                Sign up
              </div>
            </div>
            <div className={DashboardStyles.ToggleContent}>
              {toggleForm === 'signIn' ? (
                <SignInForm schoolAwards={true} redirect={false} />
              ) : (
                <SignUpForm schoolAwards={true} redirect={false} />
              )}
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  )
}

export default BSALogin
